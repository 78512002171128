<template>
    <div>
        <CRow>
            <CCol sm="6" md="4">
                <CCard accent-color="success">
                    <CCardHeader>Development</CCardHeader>
                    <CCardBody>Development Instance using Soldb_Dev<br />(The Doctors Playground)</CCardBody>
                    <CCardFooter>
                        <CCol col="6" sm="4" md="2" xl class="mb-3 mb-xl-0">
                            <CButton block color="success" @click="updateEnv('dev' )" >Manage Dev</CButton>
                        </CCol>
                    </CCardFooter>
                </CCard>
            </CCol>
            <CCol sm="6" md="4">
                <CCard accent-color="warning">
                    <CCardHeader>QA</CCardHeader>
                    <CCardBody>Quality Assurance Instance using Soldb_QA<br />(Primary Testing Environment)</CCardBody>
                    <CCardFooter>
                        <CCol col="6" sm="4" md="2" xl class="mb-3 mb-xl-0">
                            <CButton block color="warning" @click="updateEnv('qa')" >Manage QA</CButton>
                        </CCol>
                    </CCardFooter>
                </CCard>
            </CCol>
            <CCol sm="6" md="4">
                <CCard accent-color="danger">
                    <CCardHeader>Live</CCardHeader>
                    <CCardBody>Live Instance using Soldb_Live<br />(Not Currently in use)</CCardBody>
                    <CCardFooter>
                        <CCol col="6" sm="4" md="2" xl class="mb-3 mb-xl-0">
                            <CButton block color="danger" @click="updateEnv('live')" >Manage Live</CButton>
                        </CCol>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    export default {
        name: 'Environments',
        methods: {
            updateEnv(env) {
                this.$root.$emit('onUpdateEnv', env);
                this.$router.push('dashboard');
            }
        }
    }
</script>
